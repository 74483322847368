<template>
  <div class="report-container">
    <h2 class="report-title">{{ $t("CustomerReport") }}</h2>

    <!-- Date Range Selection -->
    <div class="date-range">
      <label for="startDate">{{ $t("StartDate") }}</label>
      <input type="date" v-model="startDate" class="date-input" />
      <label for="endDate">{{ $t("EndDate") }}</label>
      <input type="date" v-model="endDate" class="date-input" />
      <button @click="clearDates" class="clear-button">{{ $t("ClearDates") }}</button>
    </div>

   

    <!-- Buttons for generating reports -->
    <div class="button-container">
      <button @click="viewReport(selectedFormat)" class="report-button" :disabled="loading">
        {{ loading && selectedFormat === 'pdf' ? $t("Loading") :  $t("ViewReport") }}
      </button>
      
      <button @click="downloadExcel" class="report-button" :disabled="loading">
        {{ loading && selectedFormat === 'excel' ? $t("Downloading") : $t("DownloadAsExcel") }}
      </button>
      
    
    </div>

    <!-- Loading Spinner -->
    <div v-if="loading" class="spinner-container">
      <div class="spinner"></div>
    </div>

    <!-- Display PDF inside an iframe -->
    <iframe v-if="reportUrl" :src="reportUrl" width="100%" height="600px" class="report-iframe"></iframe>
  </div>
</template>

<script>
import axios from "@/axios.js";

export default {
  data() {
    return {
      reportUrl: '', // URL for displaying the PDF in iframe
      loading: false, // Track loading state
      startDate: '', // Start date for filtering
      endDate: '', // End date for filtering
      selectedFormat: 'pdf' // Default format for report
    };
  },
  methods: {
    async viewReport(format = 'pdf') {
      this.loading = true; // Start loading
      try {
        const url = `/API/Reports/GetAllCustomers?format=${format}&startDate=${this.startDate}&endDate=${this.endDate}`;
        
        // Send a request to get the PDF or Excel data from the server
        const response = await axios.get(url, { responseType: 'blob' });

        // If the format is PDF, display the PDF in the iframe
        if (format === 'pdf') {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          this.reportUrl = URL.createObjectURL(blob);
        }
      } catch (error) {
        console.error("Error loading report:", error);
        // Implement a notification for user feedback
        this.$notify({
          title: 'Error',
          message: 'Failed to load report. Please try again.',
          type: 'error'
        });
      } finally {
        this.loading = false; // End loading
      }
    },

    async downloadExcel() {
      this.loading = true; // Start loading
      try {
        const url = `/API/Reports/GetAllCustomers?format=excel&startDate=${this.startDate}&endDate=${this.endDate}`;
        
        // Fetch Excel data from the server
        const response = await axios.get(url, { responseType: 'blob' });
        
        // Create a Blob object for Excel and trigger download
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'CustomerReport.xlsx');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading Excel:", error);
        this.$notify({
          title: 'Error',
          message: 'Failed to download Excel. Please try again.',
          type: 'error'
        });
      } finally {
        this.loading = false; // End loading
      }
    },

    

    clearDates() {
      this.startDate = '';
      this.endDate = '';
    }
  },
  created() {
    // Load the PDF report when the component is mounted
    this.viewReport();
  }
};
</script>

<style scoped>
.report-container {
  padding: 20px; /* Add some padding around the container */
  max-width: 800px; /* Limit the maximum width */
  margin: auto; /* Center the container */
  border: 1px solid #ddd; /* Border for the container */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  background-color: #f9f9f9; /* Light background */
}

.report-title {
  text-align: center; /* Center the title */
  margin-bottom: 20px; /* Space below the title */
  color: #333; /* Title color */
}

.date-range {
  display: flex; /* Flex layout for date inputs */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space between elements */
  margin-bottom: 20px; /* Space below the date range */
}

.date-input {
  margin: 0 10px; /* Space around inputs */
  padding: 10px; /* Padding inside inputs */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ccc; /* Border */
}

.clear-button {
  background-color: #f44336; /* Red background */
  color: white; /* White text */
  border: none; /* No border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  margin-left: 10px; /* Space to the left */
}

.clear-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

.format-select {
  margin: 10px 0; /* Space above and below select */
  padding: 10px; /* Padding inside select */
  font-size: 16px; /* Font size */
}

.button-container {
  display: flex; /* Flex layout for buttons */
  justify-content: center; /* Center buttons */
  flex-wrap: wrap; /* Wrap buttons to the next line if needed */
}

.report-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 10px 20px; /* Add some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Display as inline-block */
  font-size: 16px; /* Font size */
  margin: 10px; /* Add margin around buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.report-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.spinner-container {
  display: flex; /* Flex layout for spinner */
  justify-content: center; /* Center spinner */
  align-items: center; /* Center vertically */
  margin: 20px 0; /* Space above and below */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%; /* Circular spinner */
  width: 40px; /* Width of spinner */
  height: 40px; /* Height of spinner */
  animation: spin 2s linear infinite; /* Spinner animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.report-iframe {
  border: none; /* Remove border */
  margin-top: 20px; /* Space above the iframe */
  border-radius: 5px; /* Rounded corners */
}
</style>
